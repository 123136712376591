<template>
  <footer class="footer max-content">
    <div class="content">
      <div class="top">
        <p class="address">{{ contact.address }}</p>
        <button class="to-top-btn" @click="toTop">{{ $t("toTop") }}</button>
      </div>
      <div class="middle">
        <div class="left">
          <div class="line">
            <div class="mask-icon phone"></div>
            <a class="text decor" :href="`tel:${contact.phone}`">
              {{ contact.phone }}
            </a>
          </div>
          <div class="line">
            <div class="mask-icon fax"></div>
            <p class="text">{{ contact.fax }}</p>
          </div>
          <div class="line">
            <div class="mask-icon email"></div>
            <a :href="`mailto:${contact.e_mail}`" class="text decor">
              {{ contact.e_mail }}
            </a>
          </div>
        </div>
        <div class="right">
          <div class="block">
            <router-link
              v-for="item in $tm('block1')"
              :key="item"
              :to="
                Tr.i18nRoute({
                  name: $rt(item.link),
                  params: { enterprise: item.company ? $rt(item.company) : '' },
                })
              "
              class="link decor"
            >
              {{ $rt(item.name) }}
            </router-link>
          </div>
          <div class="block">
            <router-link
              v-for="item in $tm('block2')"
              :key="item"
              :to="
                Tr.i18nRoute({
                  name: $rt(item.link),
                  params: { enterprise: item.company ? $rt(item.company) : '' },
                })
              "
              class="link decor"
            >
              {{ $rt(item.name) }}
            </router-link>
          </div>
          <div class="block">
            <a href="https://konar-vacancies.ru" class="link decor">
              карьерный портал
            </a>
            <a href="https://vk.com/konarchel" class="link decor">
              мы вконтакте
            </a>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="left">
          <p class="text">
            © Все права защищены, Промышленная группа КОНАР,
            {{ currentYear }} г.
          </p>
        </div>
        <div class="right">
          <router-link :to="{ name: 'personal-data' }" class="text decor">
            политика конфиденциальности
          </router-link>
          <!--          <p class="text decor">настройки cookies</p>-->
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Tr from "@/i18n/translation";

export default {
  data() {
    return {
      contact: {},
    };
  },
  async created() {
    await this.getContact();
  },
  computed: {
    Tr() {
      return Tr;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
    currentYear: function () {
      return new Date().getFullYear();
    },
  },
  watch: {
    async currentLocale(newVal) {
      await this.getContact();
    },
  },
  methods: {
    toTop() {
      if (this.$route.name === "Home") {
        this.$emit("goToTop");
        this.setSliderStart = true;
      }
      window.scrollTo(0, 0);
    },
    async getContact() {
      await this.$api
        .get(
          `/contacts?section=Без раздела&description=Общие контакты КОНАР&lang=${this.$i18n.locale}`
        )
        .then((res) => {
          this.contact = res.data[0];
          this.contact.phone = this.contact.phone[0].phone;
          this.contact.e_mail = this.contact.e_mail[0].email;
        });
    },
  },
};
</script>

<i18n lang="json">
{
  "ru": {
    "toTop": "Наверх",
    "block1": [
      {
        "name": "ПГ КОНАР",
        "link": "Home"
      },
      {
        "name": "о компании",
        "link": "company/about-company"
      },
      {
        "name": "новости",
        "link": "news"
      },
      {
        "name": "контакты",
        "link": "contacts"
      }
    ],
    "block2": [
      {
        "name": "сертификаты",
        "link": "serts"
      },
      {
        "name": "фотогалерея",
        "link": "company/photo-gallery"
      },
      {
        "name": "видеогалерея",
        "link": "company/video-gallery"
      }
    ]
  },
  "en": {
    "toTop": "Back to top",
    "block1": [
      {
        "name": "IG KONAR",
        "link": "Home"
      },
      {
        "name": "about",
        "link": "company/about-company"
      },
      {
        "name": "news",
        "link": "news"
      },
      {
        "name": "contacts",
        "link": "contacts"
      }
    ],
    "block2": [
      {
        "name": "certificates",
        "link": "serts"
      },
      {
        "name": "photo gallery",
        "link": "company/photo-gallery"
      },
      {
        "name": "video gallery",
        "link": "company/video-gallery"
      }
    ]
  },
  "it": {
    "toTop": "Back to top",
    "block1": [
      {
        "name": "GI KONAR",
        "link": "Home"
      },
      {
        "name": "about",
        "link": "company/about-company"
      },
      {
        "name": "news",
        "link": "news"
      },
      {
        "name": "contacts",
        "link": "contacts"
      }
    ],
    "block2": [
      {
        "name": "certificates",
        "link": "serts"
      },
      {
        "name": "photo gallery",
        "link": "company/photo-gallery"
      },
      {
        "name": "video gallery",
        "link": "company/video-gallery"
      }
    ]
  }
}
</i18n>

<style lang="sass" scoped>
@import "@/assets/styles/base/adaptive"
.footer
  width: 100%
  height: fit-content
  background: var(--main-color)
  border-top-left-radius: 20px
  border-top-right-radius: 20px
  .content
    padding: 0
    margin: 0 115px 0 115px
    .top
      display: flex
      flex-direction: row
      justify-content: space-between
      .address
        padding: 38px 0
        color: var(--white-color)
        font-size: 1.5rem
        font-weight: 600
        line-height: 100%
        text-transform: uppercase
      .to-top-btn
        margin-top: 20px
        width: fit-content
        height: fit-content
        background: var(--white-color)
        display: flex
        justify-content: center
        align-items: center
        color: var(--main-color)
        font-size: 0.875rem
        font-weight: 700
        line-height: 100%
        padding: 18px 45px
        transform: perspective(1px) translateZ(0)
        position: relative
        transition: color 0.3s
        overflow: hidden
        border-radius: 100px
    .middle
      display: grid
      grid-template-columns: 0.4fr 0.45fr
      justify-content: space-between
      padding: 30px 0
      .left
        display: flex
        flex-direction: column
        gap: 10px
        .line
          display: flex
          flex-direction: row
          gap: 30px
          .mask-icon
            background-color: var(--white-color)
            width: 14px
            height: 14px
            mask-size: contain
            transition: .3s ease-out
          .phone
            mask: url(@/assets/images/icons/footer_phone.svg) no-repeat center
          .fax
            mask: url(@/assets/images/icons/footer_fax.svg) no-repeat center
          .email
            mask: url(@/assets/images/icons/footer_email.svg) no-repeat center
          .text
            color: var(--white-color)
            font-size: 0.875rem
            font-weight: 600
            line-height: 100%
            text-transform: uppercase
            padding-bottom: 3px
          .decor
            display: flex
            transform: perspective(1px) translateZ(0)
            position: relative
            overflow: hidden
            padding-bottom: 3px
            &:before
              content: ""
              position: absolute
              z-index: -1
              left: 0
              right: 0
              bottom: 0
              background: var(--white-color)
              height: 2px
              transform: translateY(2px)
              transition: transform 0.3s ease-out
          .decor:hover:before, .decor:focus:before, .decor:active:before
            transform: translateY(0)
      .right
        display: flex
        flex-direction: row
        justify-content: space-between
        gap: 10px
        .block
          display: flex
          flex-direction: column
          width: 100%
          gap: 10px
          .link
            color: var(--white-color)
            font-size: 0.875rem
            font-weight: 700
            line-height: 100%
            text-transform: uppercase
            width: fit-content
          .decor
            display: flex
            transform: perspective(1px) translateZ(0)
            position: relative
            overflow: hidden
            padding-bottom: 3px
            &:before
              content: ""
              position: absolute
              z-index: -1
              left: 0
              right: 0
              bottom: 0
              background: var(--white-color)
              height: 2px
              transform: translateY(2px)
              transition: transform 0.3s ease-out
          .decor:hover:before, .decor:focus:before, .decor:active:before
            transform: translateY(0)
    .bottom
      display: grid
      grid-template-columns: 0.4fr 0.45fr
      justify-content: space-between
      padding: 34px 0
      .text
        color: var(--white-color)
        font-size: 0.75rem
        font-weight: 600
        line-height: 100%
        text-transform: uppercase
      .decor
        display: flex
        transform: perspective(1px) translateZ(0)
        position: relative
        overflow: hidden
        padding-bottom: 3px
        &:before
          content: ""
          position: absolute
          z-index: -1
          left: 0
          right: 0
          bottom: 0
          background: var(--white-color)
          height: 2px
          transform: translateY(2px)
          transition: transform 0.3s ease-out
      .decor:hover:before, .decor:focus:before, .decor:active:before
        transform: translateY(0)
      .left
        display: flex
        flex-direction: row
        align-items: center
        .text
          padding-bottom: 3px
      .right
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-end
@media only screen and (max-width: 1400px) and (min-width: 1201px)
  .footer
    .content
      margin: 0 40px
      .middle
        grid-template-columns: 0.4fr 0.55fr
      .bottom
        grid-template-columns: 0.4fr 0.55fr
@media only screen and (max-width: $bp-pc)
  .footer
    .content
      margin: 0 65px
@media only screen and (max-width: 1000px)
  .footer
    .content
      .middle
        grid-template-columns: 0.3fr 0.62fr
      .bottom
        grid-template-columns: 0.3fr 0.62fr
@media only screen and (max-width: $bp-tablet-landscape-up)
  .footer
    border-top-left-radius: 10px
    border-top-right-radius: 10px
    .content
      margin: 0 45px
      .top
        .address
          padding: 30px 0
        .to-top-btn
          display: none
      .middle
        display: flex
        flex-direction: column
        gap: 50px
        .left
          gap: 15px
          .line
            .text
              padding-bottom: 0
            .decor
              padding-bottom: 0
              &:before
                content: unset
        .right
          .block
            gap: 15px
            .decor
              padding-bottom: 0
              &:before
                content: unset
      .bottom
        grid-template-columns: 0.3fr 0.66fr
        padding: 24px 0 45px
        .decor
          padding-bottom: 0
          &:before
            content: unset
        .left
          .text
            padding-bottom: 0
        .right
          .text
            &:last-child
              text-align: end
@media only screen and (max-width: $bp-tablet)
  .footer
    .content
      margin: 0 17px
      .top
        .address
          padding: 30px 0 20px
          font-size: 1.125rem
      .middle
        padding: 20px 0
        .right
          display: grid
          grid-template-columns: repeat(2, 1fr)
          grid-gap: 40px 0
      .bottom
        padding: 20px 0 40px
        display: flex
        flex-direction: column-reverse
        gap: 10px
        .left
          justify-content: center
          .text
            text-align: center
        .right
          flex-direction: column-reverse
          gap: 10px
          justify-content: center
          .text, .text:last-child
            text-align: center
</style>
