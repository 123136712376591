import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/styles/index.sass";
import { createHead } from "@vueuse/head";
import api from "@/http/api.js";
import axios from "axios";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/lazy";
import i18n from "./i18n";
import { store } from "./store";
import VuePictureSwipe from "vue3-picture-swipe";

import SwiperCore, {
  Pagination,
  Navigation,
  Autoplay,
  Controller,
  Mousewheel,
  FreeMode,
  EffectFade,
  Zoom,
  Lazy,
  EffectCoverflow,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([
  Pagination,
  Navigation,
  Autoplay,
  Controller,
  Mousewheel,
  FreeMode,
  EffectFade,
  EffectCoverflow,
  Zoom,
  Lazy,
]);

const axiosInstance = axios.create(api);
const head = createHead();

const app = createApp(App);
app.config.globalProperties.$api = { ...axiosInstance };

app
  .use(router)
  .use(i18n)
  .use(head)
  .use(store)
  .component("vue-picture-swipe", VuePictureSwipe)
  .component("swiper", Swiper)
  .component("swiper-slide", SwiperSlide)
  .mount("#app");
