import { RouterView } from "vue-router";

import { getProducts } from "../helpers/getDynamicRoutes.js";
export const productRoutes = [
  {
    path: "products",
    component: RouterView,
    children: [
      {
        path: "",
        name: "products",
        component: () => import("@/views/Production/NewProductsPage.vue"),
      },
      {
        path: "metal-structures",
        name: "products/metal-structures",
        component: () => import("@/views/Production/MetalPage.vue"),
      },
      {
        path: "screws",
        name: "products/screws",
        component: () => import("@/views/Production/ScrewsPage.vue"),
      },
      {
        path: ":productItem",
        name: "products/product-item",
        component: () =>
          import("@/views/templates/Production/ProductionItemPage.vue"),
        meta: {
          sitemap: {
            slugs: async () => getProducts(),
          },
        },
      },
      {
        path: "forgings",
        name: "products/forgings",
        component: () => import("@/views/Production/ForgingsPage.vue"),
      },
      {
        path: "electrocar-detail",
        name: "products/electrocar-detail",
        component: () => import("@/views/Production/ElectrocarPage.vue"),
      },
      {
        path: "service",
        name: "products/service",
        component: () => import("@/views/Production/ServicePage.vue"),
      },
      {
        path: "rotors",
        name: "products/rotors",
        component: () => import("@/views/Production/RotorsPage.vue"),
      },
      {
        path: "steering-columns",
        name: "products/steering-columns",
        component: () => import("@/views/Production/SteeringColumnsPage.vue"),
      },
      {
        path: "steel-casting",
        name: "products/steel-casting",
        component: () => import("@/views/Production/SteelCastingPage.vue"),
      },
      {
        path: "mechanical-restoration",
        name: "products/mechanical-restoration",
        component: () => import("@/views/Production/MechanicalPage.vue"),
      },
      // {
      //   path: "electrical-machine-parts",
      //   name: "products/electrical-machine-parts",
      //   component: () => import("@/views/Production/DetailsPage.vue"),
      // },
      {
        path: "dynamic-equipment",
        name: "products/dynamic-equipment",
        component: () => import("@/views/Production/DynamicEq.vue"),
      },
    ],
  },
];
